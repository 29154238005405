@font-face {
  font-family: 'Plain';
  src: url('../public/fonts/Plain-Regular.woff2') format('woff2'),
  url('../public/fonts/Plain-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Plain';
  src: url('../public/fonts/Plain-Light.woff2') format('woff2'),
  url('../public/fonts/Plain-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Plain';
  src: url('../public/fonts/Plain-Bold.woff2') format('woff2'),
  url('../public/fonts/Plain-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
